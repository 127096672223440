body {
  margin: 0;
  padding: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

.sign-main-container {
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1Fr;
  grid-template-areas: "left-sign-container right-sign-container";
}

.sign-left-container {
  grid-area: left-sign-container;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sign-right-container {
  grid-area: right-sign-container;
  background-image: url(../images/group.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.signup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40%;
}

.signup-container-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
}

.signup-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  color: #000000;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
}

.signup-subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #8e8e8e;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
}

.sign-field {
  height: 50px;
  margin-top: 8px;
  width: 100%;
}

.text-danger {
  margin-top: 0px;
  color: rgb(255, 42, 42);
  font-size: 0.8rem;
  text-align: right;
}

.label-field {
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #545454;
  margin-bottom: 0px;
  margin-top: 15px;

  text-align: left;
}

.red {
  color: red;
}

#signup-btn {
  margin-top: 30px;
}

.bold-text-blue {
  color: #6366f1;
}

.label-field-normal {
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #545454;
  margin-bottom: 0px;
  margin-top: 15px;
  text-align: center;
}

.image-right {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.logo {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
}

@media (max-width: 400px) {
  .logo {
    display: none;
  }

  .logo-text {
    display: block;
    text-align: center;
    font-size: 16px;
    margin-top: 10%;
    color: #000000;
  }
}

.forgot-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

#signin-btn {
  width: 120px;
}

.label-field-forgot {
  display: flex;
  align-items: center;
  justify-content: left;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #545454;
  margin-bottom: 0px;
}

.title-list-error {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 3px;
  color: #000000;
  text-align: left;
}

.single-line-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.bloc-error {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-items: left;
  flex-direction: column;
}

.title-list-error {
  margin-top: 10px;
}

.label-field-password {
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #545454;
  margin-bottom: 0px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.second-password-div {
  width: 100%;
  margin-top: 20px;
}

.show-password {
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #545454;
}

.forgot-psw-container {
  width: 40%;
}

@media screen and (min-width: 200px) and (max-width: 999px) {
  /**HOMEPAGE**/

  .signup-container {
    width: 60%;
  }

  .logo {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%);
  }

  .sign-main-container {
    display: grid;
    height: 100vh;
    grid-template-columns: 1fr;
    grid-template-rows: 1Fr;
    grid-template-areas: "left-sign-container";
  }

  .sign-left-container {
    width: 100%;
  }

  .sign-right-container {
    display: none;
  }

  .sign-right-container-2 {
    display: none !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 399px) {
  /**HOMEPAGE**/

  .signup-container {
    width: 80%;
  }

  .logo {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%);
  }

  .sign-main-container {
    display: grid;
    height: 100vh;
    grid-template-columns: 1fr;
    grid-template-rows: 1Fr;
    grid-template-areas: "left-sign-container";
  }

  .sign-left-container {
    width: 100%;
  }

  .sign-right-container {
    display: none;
  }

  .custom-button {
    width: 200px;
  }

  .internet-connexion-alert {
    margin-top: 25px;
    width: 100%;
    height: 60px;
    background-color: yellow;
  }
}
