.profil-and-button-container {
  display: flex;
  flex-direction: row;
  height: 120;
  align-items: center;
}

.profil-and-button-container-mobile {
  display: flex;
  flex-direction: row;
  height: 120;
  align-items: center;
}

.profil-and-button-container-v2-mobile {
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
}

.bold-title {
  font-weight: 700;
  font-size: 30px;
}

.grey-subtitle {
  font-weight: 400;
  font-size: 20px;
  color: #949e9e;
}

.grey-subtitle-2 {
  font-weight: 400;
  font-size: 20px;
  color: #666a6a;
  margin-top: 20px;
  margin-bottom: 20px;
}

.title-a1 {
  font-weight: 500;
  font-size: 20px;
  color: rgb(84, 84, 84);
  margin-left: 60px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.profile-title-setting {
  margin-left: 60px;
}

.profile-title-setting-mobile {
  margin-left: 20px;
}

.role-title-setting {
  margin-top: 50px;
  margin-left: 60px;
}

.role-title-setting-mobile {
  margin-top: 30px;
  margin-left: 20px;
}

.password-title-setting {
  margin-top: 50px;
  margin-left: 40px;
}

.input-line > div:first-child {
  margin-right: 50px;
}

.input-account {
  margin-top: 10px;
}

.role-widget {
  margin-top: 20px;
  margin-bottom: 50px;
  background: #6c7e9b;
  border: 0.5px solid #bebcbc;
  border-radius: 5px;
  height: 42px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.role-widget-mobile {
  margin-top: 0px;
  margin-bottom: 0px;
  background: #6c7e9b;
  border: 0.5px solid #bebcbc;
  border-radius: 5px;
  height: 30px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
}

.second-line-setting {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.bottom-line-setting {
  width: 100%;
  height: 120px;
  border-top: 1px solid #e2e8f0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bottom-line-setting-mobile-v2 {
  width: 100%;
  height: 60px;
  border-top: 1px solid #e2e8f0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.bottom-line-setting-mobile {
  width: 100%;
  height: 120px;
  border-top: 1px solid #e2e8f0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.smaller-text {
  font-size: 10px;
  margin-left: 10px;
}

.smaller-text2 {
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
}

.title-line-medium5 {
  margin-top: 30px;
  width: 75%;
  height: 20px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #9a9797;
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 5px;
}

.title-medium5-block1 {
  padding-left: 10px;
  width: 30%;
  height: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.title-medium5-block1-mobile {
  padding-left: 10px;
  width: 33.3%;
  height: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.font-mobile {
  font-size: 12px;
  text-align: center;
}

.font-mobile2 {
  font-size: 12px;
  text-align: center;
}

.font-mobile3 {
  font-size: 12px;
  text-align: center;
}
.title-medium5-block2 {
  padding-left: 10px;
  width: 25%;
  height: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.title-medium5-block3 {
  padding-left: 10px;
  width: 15%;
  height: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.title-medium5-block1bis {
  padding-left: 10px;
  width: 30%;
  height: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.title-medium5-block1bis-v2 {
  padding-left: 10px;
  width: 50%;
  height: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-medium5-block2bis {
  padding-left: 10px;
  width: 20%;
  height: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.title-medium5-block3bis {
  padding-left: 10px;
  width: 20%;
  height: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
}

/* .title-medium-block2 {
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
}

.title-medium-block2bis {
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
}

.title-medium-block3 {
  padding-left: 15px;
  width: 27%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
} */

.role-line-block1 {
  width: 30%;
  height: 100%;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.role-line-block1-bis {
  width: 50%;
  height: 100%;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  text-align: center;
  justify-content: center;
}
.role-line-block1-tris {
  width: 50%;
  height: 100%;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  text-align: center;
}

.role-line-block1-mobile {
  height: 100%;
  font-weight: 500;
  font-size: 12px;
  /* display: flex;
  align-items: center; */
  padding-left: 10px;
}

.ellipsis-mobile {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.parent {
  display: flex;
  align-items: center;
  width: 33.3%;
}

.parent2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.3%;
}

.role-line-block1bis {
  width: 20%;
  height: 100%;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.role-line-block2 {
  width: 25%;
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.role-line-block3 {
  width: 15%;
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.role-line-block3-mobile {
  /* width: 33.3%; */
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.role-line-block3bis {
  width: 10%;
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  background-color: pink;
}

.role-line-block4 {
  width: 5%;
  height: 100%;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  background-color: red;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.2s;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formLineMedium5 {
  width: 75%;
  height: 50px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 6px;
}

.formLineMedium5Mobile {
  width: 75%;
  height: 50px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 6px;
}

.formLineMedium6 {
  width: 90%;
  height: 50px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 6px;
}

.button-pointer {
  cursor: pointer;
}
