.navbar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 15%;
  height: 100vh;
  background: #1e293b;
  color: white;
  float: left;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.navbar-container-v2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 15%;
  height: 100vh;
  background: #f8f8fa;
  color: white;
  float: left;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  border-right: 1px solid #e9e9e9;
}

.navbar-container2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 8%;
  height: 100vh;
  background: #1e293b;
  color: white;
  float: left;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.mobile-navbar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  color: white;
  float: left;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  overflow-y: scroll;
  scrollbar-width: none; /* Pour Firefox */
  -ms-overflow-style: none;
}

.drawer-background {
  background-color: rgba(
    0,
    0,
    0,
    0.9
  ) !important; /* Ajustez l'opacité à votre convenance */
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
}

.sidebar-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* color: #979eb3; */
  margin-bottom: 4px;
  height: 50px;
}

.sidebar-title-v2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #979eb3;
  margin-bottom: 2px;
  height: 40px;
}

.sidebar-title-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* color: #979eb3; */
  margin-bottom: 4px;
  height: 30px;
}

.container-active {
  background-color: #e3ebf9;
  color: #1565c0;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
}

.icon-menu {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-navbar {
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.link-navbar-v2 {
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 14px;
}

.menu-title {
  font-size: 15px;
  font-weight: 500;
}

.menu-title-mobile {
  font-size: 18px;
  font-weight: 400;
  padding-left: 20px;
}

.avatar-profil {
  width: 72px;
  height: 72px;
}

.widget-profile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-top: 15px;
  height: 100%;
  width: 100%;
}

.widget-profile-container-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  height: 100%;
  width: 100%;
}

.widget-profile-container-bis {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-top: 0px;
  height: 100%;
  width: 100%;
}

.profil-widget-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.profil-widget-container-mobile {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: auto;
  align-items: center;
  align-self: center;
  align-content: center;
}

@media screen and (max-width: 1200px) {
  .widget-profile-container {
    flex-direction: row;
    padding-left: 50px;
  }
  .profil-name {
    font-size: 15px;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .profil-name span {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 100%;
    width: 100%;
  }
  .avatar-container {
    margin-left: 0 !important;
  }
  .profil-widget-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container-chip-role-profile {
    justify-content: center !important;
  }
  .profil-role {
    margin-left: 0px !important;
  }
  .menu-items-container {
    padding-left: 12px !important;
  }
  .displayed-name-span {
    text-align: center !important;
  }
}

.displayed-name-span {
  text-align: left;
}

.profil-name {
  /* font-size: 20px; */
  font-weight: 400;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.avatar-container {
  display: flex;
  margin-left: 20px;
}

.avatar-container-ai {
  display: flex;
  margin-left: 10px;
}

.container-chip-role-profile {
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-self: center;
  justify-content: flex-start;
  margin-top: 3px;
}

.profil-role {
  margin-left: 10px;
  margin-top: 8px;
  font-size: 10px;
  background-color: #1f55e8;
  color: white;
  border-radius: 10px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  padding-bottom: 3px;
}

.profil-role-mobile {
  margin-left: 0px;
  margin-top: 3px;
  font-size: 10px;
  background-color: #1f55e8;
  color: white;
  border-radius: 10px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  padding-bottom: 3px;
}

.profil-role-bis {
  margin-left: 10px;
  margin-top: 3px;
  font-size: 10px;
  background-color: #1f55e8;
  color: white;
  border-radius: 10px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  padding-bottom: 3px;
}

.menu-items-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-left: 27px;
}

.menu-items-container-mobile {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  padding-left: 0px;
}

.navbar-top-group {
  margin-top: 0px;
  height: 100%;
}

.navbar-top-group-mobile {
  margin-top: 0px;
  height: 100%;
}

.menu-separator {
  /* margin: 15px 0; */
  padding: 0px 0;
  border-top: 1px solid #ccc;
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
  padding-bottom: 10px;
}

.navbar-bottom-group {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 10%;
}

.navbar-bottom-group-v2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  align-self: center;
  justify-content: center;
  height: 10%;
}

.sidebar-title-signout {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  padding-left: 27px;
  margin-bottom: 23px;
}

/* .sidebar-title:hover .menu-title {
  color: #8c8efc;
  font-weight: 600;
} */

.sidebar-title-mobile:hover {
  /* background-color: #e3ebf9; */
  margin-right: 10px;
  padding-left: 10px;
}

.sidebar-title-v2:hover {
  /* background-color: #e3ebf9; */
  margin-right: 10px;
  padding-left: 10px;
}

.link-navbar.active {
  color: #8c8efc;
  font-weight: 900;
}

.menu-title.active {
  color: #1565c0;
  font-weight: 600;
}

.menu-title-mobile.active {
  color: #0122f9;
  font-weight: 700;
}

.navbar-scrollable {
  max-height: 100vh; /* Limite la hauteur maximale à 100% de la hauteur de la fenêtre */
  overflow-y: auto; /* Permet le défilement vertical si le contenu dépasse la hauteur maximale */
}

@media (max-width: 1600px) {
  .sidebar-title {
    margin-bottom: 0px;
    height: 45px;
  }

  .container-active {
    padding: 8px;
    margin-right: 8px;
  }

  .icon-menu {
    margin-right: 8px;
    transform: scale(0.8); /* Slightly reduce icon size */
  }

  .link-navbar-v2 {
    font-size: 11px; /* Reduce font size */
  }

  .menu-title {
    font-size: 12px; /* Reduce font size */
  }

  .menu-title.active {
    font-weight: 550; /* Slightly reduce font weight */
  }
}
