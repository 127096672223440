.dropdown-select {
  width: auto;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #bebcbc;
  margin-top: 2px;
  height: 40px;
}

.dropdown-select-fixed {
  width: 150px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #bebcbc;
  margin-top: 2px;
  height: 40px;
}

.input-label-dropdown {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 5px;
}
