.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.priority-option {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.priority-option.selected {
  background-color: #caecff; /* Couleur de fond pour l'option sélectionnée */
  border-color: #47b3ed; /* Couleur de la bordure pour l'option sélectionnée */
}

.priority-option .dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 8px;
}

.Mui-error {
  border-color: red;
}

.priority-option .low .dot {
  background-color: green;
}

.priority-option .medium .dot {
  background-color: orange;
}

.priority-option .high .dot {
  background-color: red;
}

/* blink.css */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 1s linear;
}

.file-item-v2 {
  display: flex;
  align-items: center;
  padding: 0px;
  width: 100%;
}

.file-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.file-name {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-weight: 500;
}

.progress-bar {
  width: 100%;
  height: 4px;
  background-color: lightblue;
  margin-top: 2px;
  margin-top: 5px;
}

.custom-phone-input {
  height: 70px; /* Ajustez cette valeur selon vos besoins */
  font-size: 16px; /* Ajustez cette valeur selon vos besoins */
  padding: 8px; /* Ajustez cette valeur selon vos besoins */
}

.custom-phone-input input {
  height: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 16px;
}

.custom-phone-input.error {
  border-color: red;
}

.joyride-step__overlay {
  opacity: 0.3 !important;
}

.disable-scroll {
  overflow: hidden;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.joyride-tooltip {
  animation: fadeIn 0.3s ease-in-out;
}

.tab-form-container-v2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0px;
  align-self: flex-start;
}

.App-link {
  color: #61dafb;
}

.seemore-container {
  width: 90%;
  margin-left: 60px;
}

.seemore-container-mobile-v2 {
  width: 100%;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
}

.alert-message {
  color: red;
  font-weight: 600;
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 40px;
}
.alert-message2 {
  color: red;
  font-weight: 600;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.alert-message3 {
  color: red;
  font-weight: 400;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.line-alert {
  flex-direction: row;
}

.status-modal-title {
  width: 100%;
  height: 50px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 5px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  background-color: #1565c0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.container-counter {
  margin-left: 60px;
  margin-bottom: 15px;
  color: #1565c0;
  font-weight: 600;
}

.container-counter-bis {
  margin-left: 20px;
  margin-bottom: 15px;
  color: #1565c0;
  font-weight: 600;
}

@media (min-width: 1550px) {
  .ai-text {
    display: block !important; /* Show text on larger screens */
  }

  .tooltip-icon {
    display: none; /* Optionally hide the tooltip on larger screens */
  }
}

.line-detail-logs {
  width: 100%;
  margin-top: 5px;
  background-color: #eaeaea;
  min-height: 40px;
  max-height: 200px;
  /* border-bottom: 1px solid lightgray; */
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding-left: 10px;
  border-radius: 5px;
}

.logs-detail-button {
  background-color: #007bff;
  color: white;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logs-detail-button:hover {
  background-color: #3399ff;
}

.modal-header {
  background-color: #007bff;
  width: 100%;
  height: 40px;
  color: white;
  display: flex;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.container-counter-tris {
  margin-left: 35px;
  margin-bottom: 15px;
  background-color: #1565c0;
  color: white;
  font-weight: 600;
  text-align: center;
  width: 140px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 8px;
}

.container-small-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 150px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.diode {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.red-diode {
  background-color: red;
  animation: blink 2s infinite;
}

.green-diode {
  background-color: green;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
