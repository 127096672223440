.main-container {
  background-color: "white";
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.right-container {
  position: absolute;
  top: 0;
  left: 15%;
  width: 85%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.right-container2 {
  position: absolute;
  top: 0;
  left: 8%;
  width: 92%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.right-container3 {
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.right-container-cheatsheet {
  position: absolute;
  top: 0;
  left: 15%;
  width: 85%;
  height: auto;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.right-container-newdesign {
  position: relative;
  top: 0;
  left: 15%;
  width: 85%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.right-container-mobile {
  position: absolute;
  /* top: 0; */
  top: 5%;
  left: 0%;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.menu-button-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  padding-left: 20px;
}

.menu-button-container-mobile {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  padding-left: 0px;
}

.drawer-background {
  background-color: rgba(
    0,
    0,
    0,
    0.7
  ) !important; /* Ajustez l'opacité à votre convenance */
}

.menu-button {
  position: relative;
  margin-left: 20px;
}

.menu-button-mobile {
  margin-left: 20px;
}

.right-container-settings {
  position: absolute;
  top: 0;
  left: 15%;
  width: 85%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right-container-settings-mobile {
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-title-swot-table {
  font-weight: 400;
  font-size: 15px;
  padding-bottom: 5px;
}

.text-title-swot-table-mobile {
  font-weight: 400;
  font-size: 12px;
  padding-bottom: 5px;
}

.text-title-swot-table2 {
  font-weight: 400;
  font-size: 12px;
  padding-bottom: 5px;
}

.text-item-swot-table {
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-item-swot-table2 {
  font-weight: 400;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titles-swot-table {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid black;
  margin-top: 40px;
  width: 90%;
  justify-content: space-around;
}

.swot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.line-swot {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  height: 60px;
}

.line-swot-bckgrd-white {
  background: white;
}

.line-swot-bckgrd-grey {
  background: #cecece;
}

.title1-swot {
  width: 20%;
}

.title2-swot {
  width: 10%;

  text-align: center;
}
.title3-swot {
  width: 12.5%;
  text-align: center;
}

.title4-swot {
  width: 15%;
  text-align: center;
}
.title5-swot {
  width: 7.5%;
  text-align: center;
}

.title6-swot {
  width: 12%;
  text-align: center;
}

.title7-swot {
  width: 16%;
  text-align: center;
}

.title7-mobile-swot {
  width: 33%;
  text-align: center;
}

.title8-mobile-swot {
  width: 33%;
  text-align: center;
}

.title9-mobile-swot {
  width: 33%;
  text-align: center;
}

.title8-swot {
  width: 6%;
  text-align: center;
}

.small-client-chip-name {
  padding-left: 10px;
  font-size: 12px;
  width: 100px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.small-client-chip-name-large {
  padding-left: 10px;
  font-size: 12px;
  width: 150px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.small-client-chip-name-mobile {
  padding-left: 10px;
  font-size: 14px;
  width: 100%;

  font-weight: bold;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
