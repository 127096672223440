body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
}

.main-container-cheatsheet {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgb(247, 247, 247); */
}

.sign-container {
  width: 30%;
  height: 200px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.main-top-title-banner-container {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.main-top-title-banner-container-bis {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-bottom: 1px solid #e5e5e5;
}

.date-container {
  width: 100%;
  height: 30px;
  padding-left: 60px;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.date-container-mobile {
  width: 100%;
  height: 30px;
  padding-left: 60px;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-top: 7px;
}

.title-text-banner {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  background: linear-gradient(270deg, #1e293b 0%, #4b6ba0 100%);
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  color: white;
  padding-left: 60px;
  padding-bottom: 3px;
}

.title-text-banner-bis {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  /* background: linear-gradient(270deg, #1e293b 0%, #4b6ba0 100%); */
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  color: black;
  padding-left: 60px;
  padding-right: 50px;
  padding-bottom: 3px;
}

.title-text-banner-mobile {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  background: linear-gradient(270deg, #1e293b 0%, #4b6ba0 100%);
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: white;
  padding-left: 60px;
  padding-bottom: 3px;
  margin-top: 10px;
}

.title-text-banner-mobile-arrow {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  background: linear-gradient(270deg, #1e293b 0%, #4b6ba0 100%);
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: white;
  padding-left: 20px;
  padding-bottom: 3px;
  margin-top: 10px;
  flex-direction: row;
}

.input-container {
  display: flex;
  flex-direction: column;
  width: 350px;
}

.input-title {
  font-weight: 400;
  font-size: 20px;
  color: #8f8f8f;
}

.input-line {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  margin-left: 60px;
}

.input-line-mobile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Nouveau */
  align-items: flex-start; /* Nouveau */
  margin-top: 15px;
  margin-left: 20px;
}

.inputAndLabelModalContainerTitle2 {
  width: "auto";
  height: "80%";
}

.inputAndLabelModalContainerTitle3 {
  width: "80%";
}

.top-subtitle-container {
  width: 100%;
  height: 30px;
  background-color: #6183bb;
}

.top-subtitle-container {
  margin-top: 30px;
  display: flex;
  align-items: center;
  color: white;
  padding-left: 60px;
  font-weight: 400;
  font-size: 21px;
}

.small-client-chip-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.small-client-chip-container-mobile-v2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
}

.small-client-chip-container-v2 {
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.small-client-chip-container-mobile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  margin-left: 10px;
}

.container-check-with-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin-panel {
  width: 100%;
}

.container-role {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.name-for-check {
  font-size: 12px;
  width: 100px;
  text-align: left;
  padding-left: 5px;
  display: inline-block;
}
.name-for-check-mobile {
  font-size: 12px;
  width: 100px;
  text-align: center;
  padding-left: 5px;
  display: inline-block;
}

.title-2items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 30px;
}

.sorting-icon {
  margin-left: 5px;
}
