.search-bar-container {
  width: 270px;
  height: 35px;
  background-color: white;
  border: 0.5px solid #bebcbc;
  border-radius: 5px;
}

.top-component-list-clients {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 100%;
  justify-content: left;
  padding-left: 60px;
  /* background-color: pink; */
}

.top-component-list-clients-mobile {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 100%;
  justify-content: left;
  padding-left: 0px;
  /* background-color: pink; */
}

.input-label-container-newdesign-mobile {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  margin-top: 10px;
  width: 100%;
  height: 50px;
}

/* .top-component-list-clients-mobile {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  justify-content: right;
} */

.top-component-list-clients-mobile-v2 {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 100%;
  justify-content: right;
}

.client-widget-container {
  width: 300px;
  height: 190px;
  background: #ffffff;
  box-shadow: 4px 4px 15px 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-left: 20px;
  margin-bottom: 40px;
}

.metrics-widget-container {
  width: 250px;
  height: 150px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.metrics-widget-container-v2 {
  width: 250px;
  height: 100px;
  /* background-color: rgb(222, 222, 222); */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;

  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1); */
}

.metrics-widget-container-v2-mobile {
  width: 250px;
  height: auto;
  /* background-color: rgb(222, 222, 222); */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;

  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1); */
}

.client-widget-container-mobile {
  width: 300px;
  height: 190px;
  background: #ffffff;
  box-shadow: 4px 4px 15px 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-left: 20px;
  margin-bottom: 40px;
}

.client-widgets-global-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.client-widgets-global-container-v2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.client-widgets-global-container-bis {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 15px;
}

.line1-client-widget {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
}

.line1-client-widget-bis {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
}

.line2-client-widget {
  padding-top: 5px;
  width: 100%;
  height: 50px;
  /* background-color: red;  */
  display: flex;
  flex-direction: row;
}

.line2-client-widget-bis {
  padding-top: 5px;
  width: 100%;
  height: 70px;
  /* background-color: red;  */
  display: flex;
  flex-direction: row;
}

.line3-client-widget {
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  /* background-color: blue; */
}

.line4-client-widget {
  width: 100%;
  height: 35px;
  display: flex;
  flex-direction: row;
}

.left-line-widget {
  width: 55%;
  background-color: white;
  display: flex;
  flex-direction: row;
}

.left-line-top-widget {
  width: 60%;
  display: flex;
  background-color: white;
  flex-direction: row;
}

.top-line-top-widget {
  border-radius: 10px;
  width: 100%;
  display: flex;
  background-color: white;
  flex-direction: row;
}

.left60-line-widget {
  width: 60%;
  background-color: white;
  display: flex;
  flex-direction: row;
}

.left60-line-widget-v2 {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
}

.left60-line-widget-mobile {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.right-line-widget {
  width: 40%;
}

.right-line-widget2 {
  padding-top: 5px;
  width: 40%;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
}

.right-line-widget3 {
  width: 40%;
  display: flex;
  align-items: flex-end;
}

.right-line-widget3-column {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.right-line-widget3-column-mobile {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5px;
}

.right40-line-widget {
  width: 40%;
}

.right40-line-widget-v2 {
  width: 100%;
}

.left1-line-widget {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left1-line-widget-mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left2-line-widget {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.left2-line-widget-mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.left50-line-widget {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}

.left100-line-widget {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}

.left50-line-widget-mobile {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}

.right50-line-widget {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d9d9d9;
}

.eye-icon {
  width: 24px;
  height: 17.3px;
}

.notes-icon {
  width: 21px;
  height: 20px;
}

.title-text-button-widget {
  font-weight: 500;
  font-size: 12px;
  color: black;
  margin-left: 10px;
}

.title-text-button-widget-mobile {
  font-weight: 600;
  font-size: 12px;
  color: black;
  margin-left: 10px;
}

.chip-staff-name-container {
  width: 90%;
  height: 90%;
  border-radius: 5px;
  background: #ebf1f9;
  display: flex;
  flex-direction: column;
}

.top-title-chip {
  font-weight: 400;
  font-size: 9px;
  color: white;
  text-align: center;
  background-color: #425677;
}

.name-chip {
  font-weight: 400;
  font-size: 13px;
  width: auto;
  padding-left: 4px;
  padding-top: 4px;
  color: black;
  /* display: flex;
  text-align: center;
  align-items: center;
  align-self: center;
  justify-content: center; */
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  text-align: center;
}

.chip-room-number-container {
  width: 90%;
  height: 90%;
  border-radius: 5px;
  background: #ebf1f9;
  display: flex;
  flex-direction: column;
}

.title-room-number {
  font-weight: 400;
  font-size: 11px;
  text-align: center;
}

.room-number {
  font-weight: 400;
  font-size: 13px;
  width: auto;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  padding-bottom: 2px;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 4px;
  vertical-align: middle;
}

.alert-container {
  margin-top: 3px;
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.alert-container-mobile {
  margin-top: 1px;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.light {
  width: 14px;
  height: 14px;
  background-color: red;
  border-radius: 7px;
}

.light-mobile {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 5px;
}

.light-yellow-mobile {
  width: 10px;
  height: 10px;
  background-color: #fdaa0b;
  border-radius: 5px;
}

.title-alert {
  font-weight: 700;
  font-size: 12px;
  margin-left: 5px;
  color: red;
}

.title-alert-mobile {
  font-weight: 500;
  font-size: 10px;
  margin-left: 5px;
  color: red;
}

.light-yellow {
  width: 14px;
  height: 14px;
  background-color: #fdaa0b;
  border-radius: 7px;
}

.title-alert-yellow {
  font-weight: 700;
  font-size: 12px;
  margin-left: 5px;
  color: #fdaa0b;
}

.title-alert-yellow-mobile {
  font-weight: 500;
  font-size: 10px;
  margin-left: 5px;
  color: #fdaa0b;
}

.light-blue {
  width: 14px;
  height: 14px;
  background-color: #0bb4fd;
  border-radius: 7px;
}

.light-blue-mobile {
  width: 10px;
  height: 10px;
  background-color: #0bb4fd;
  border-radius: 5px;
}

.title-alert-blue {
  font-weight: 700;
  font-size: 12px;
  margin-left: 5px;
  color: #0bb4fd;
}

.title-alert-blue-mobile {
  font-weight: 500;
  font-size: 10px;
  margin-left: 5px;
  color: #0bb4fd;
}

.chip-status {
  font-size: 11px;
  color: white;
  border-radius: 50px;
  width: 65px;
  height: 15px;
  line-height: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  padding: 3px;
}

.chip-status-mobile {
  font-size: 11px;
  color: white;
  border-radius: 50px;
  width: auto;
  height: 15px;
  line-height: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  padding: 3px;
}

.chip-status2 {
  font-size: 9px;
  color: white;
  border-radius: 50px;
  width: 60px;
  height: 15px;
  line-height: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  padding: 3px;
}

.chip-green {
  background-color: green;
}

.chip-aftercare {
  background-color: #e7900d;
}

.chip-incoming {
  background-color: #6366f1;
}

.chip-mathome {
  background-color: #f02ddd;
}

.chip-inactive {
  background-color: #f02d2d;
}

.chip-lead {
  background-color: #2dc9f0;
}

.chip-clinical-assessment {
  background-color: #f0742d;
}

.chip-continued-therapy {
  background-color: #bc3efc;
}

.chip-denied-admission {
  background-color: #8a2be2; /* Couleur violette */
}

.chip-potential {
  background-color: #0d47a1; /* Bleu foncé */
}

.ribon-color-denied-admission {
  background-color: #8a2be2; /* Couleur violette */
}

.ribon-color-potential {
  background-color: #0d47a1; /* Bleu foncé */
}

.left-top-30 {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  /* background-color: grey; */
}

.left-top-50 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
}

.left-top-70 {
  width: 60%;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 3px;
  color: black;
}

.widget-firstname {
  width: 110px;
  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.widget-lastname {
  width: 110px;
  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.client-widget-info-text1 {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.client-widget-info-text1bis {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.client-widget-info-text2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 2px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.client-widget-info-text3 {
  margin-left: 7px;
  margin-top: 1px;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 1px;
  cursor: pointer;
}

.client-widget-info-text3-notbold {
  margin-left: 7px;
  margin-top: 1px;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 1px;
  cursor: pointer;
}

.left-line-widget-column {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.left-line-widget-column-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.link-widget {
  text-decoration: none;
}

.top-component-list-client {
  width: 90%;
  height: 125px;
}

.top-component-list-client-bis {
  width: 90%;
  height: 125px;
  margin-bottom: 20px;
}

.top-component-list-client-bis-mobile-v2 {
  width: 100%;
  height: 125px;
  margin-bottom: 20px;
}

.client-profil-sub-container {
  width: 100%;
  height: 125px;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  padding-left: 60px;
}

.client-profil-sub-container-mobile-v2 {
  width: 100%;
  height: 125px;
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  padding-left: 0px;
}

.line1-title-client-profile {
  width: 100%;
  height: auto;
  min-height: 85px;
}

.line2-title-client-profile {
  width: 100%;
  max-width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
}

.line1-sub-client-profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.line1-sub-client-profile-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.line1-sub-client-profile-mobile-v2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: lightblue;
}

.profile-client-name {
  font-weight: 400;
  font-size: 30px;
  line-height: 41px;
  margin-left: 20px;
}

.profile-client-name-mobile-v2 {
  font-weight: 400;
  font-size: 30px;
  /* line-height: 41px; */
  margin-left: 0px;
}

.profile-client-name-mobile {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin-left: 20px;
}

.block1 {
  display: flex;
  flex-direction: row;
}

.block1-mobile {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.block2 {
  display: flex;
  flex-direction: row;
}

.profile-client-subtext {
  font-weight: 300;
  font-size: 22px;
  line-height: 25px;
  margin-left: 20px;
  margin-top: 5px;
}

.profile-client-subtext-mobile {
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  margin-left: 20px;
  margin-top: 5px;
}

.ribon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 40px;
  padding-left: 17px;
  padding-right: 17px;
  color: white;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  border-radius: 10px;
  margin-right: 10px;
}

.ribon-container-small {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 20px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  border-radius: 30px;
  margin-right: 10px;
}

.ribon-container-medium {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  color: white;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  border-radius: 30px;
  margin-right: 10px;
}

.ribon-container-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 30px;
  padding-left: 17px;
  padding-right: 17px;
  color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  border-radius: 15px;
  margin-bottom: 10px;
  margin-right: 5px;
}

.ribon-container-mobile-v2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 20px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  border-radius: 15px;
  margin-bottom: 0px;
  margin-right: 5px;
}

.ribon-color-inhouse {
  background-color: green;
}

.ribon-color-aftercare {
  background-color: #e7900d;
}

.ribon-color-incoming {
  background-color: #6366f1;
}

.ribon-color-mathome {
  background-color: #f02ddd;
}

.ribon-color-inactive {
  background-color: #f02d2d;
}

.ribon-color-clinical-assessment {
  background-color: #f0742d;
}

.ribon-color-continued-therapy {
  background-color: #bc3efc;
}

.ribon-color-hot-lead {
  background-color: #2dc9f0;
}

.ribon-color-potential {
  background-color: #0d47a1;
}

.ribon-primary-addiction {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 40px;
  padding-left: 17px;
  padding-right: 17px;
  color: white;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  background-color: red;
  white-space: nowrap;
  border-radius: 10px;
  margin-right: 10px;
}

.ribon-primary-addiction-mobile {
  margin-left: 0px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 30px;
  padding-left: 17px;
  padding-right: 17px;
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  background-color: red;
  white-space: nowrap;
  border-radius: 15px;
  margin-bottom: 10px;
}

.ribon-primary-addiction-mobile-v2 {
  margin-left: 0px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 20px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  background-color: red;
  white-space: nowrap;
  border-radius: 15px;
  margin-bottom: 0px;
}

@media (max-width: 1100px) {
  /* .ribon-primary-addiction {
    font-size: 16px;
  }
  .ribon-container {
    font-size: 16px;
  }
  .ribon-information {
    font-size: 14px;
    color: yellow;
  } */
}

.ribon-information {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 40px;
  padding-left: 17px;
  padding-right: 17px;
  color: black;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  background-color: white;
  /* border: 2px solid #dbdbdb; */
  border-right: 2px solid #dbdbdb;
  border-top: 2px solid #dbdbdb;
  border-bottom: 2px solid #dbdbdb;
  border-left: 2px solid #dbdbdb;
  white-space: nowrap;
  border-radius: 10px;
  margin-right: 10px;
}

.ribon-information-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 30px;
  padding-left: 17px;
  padding-right: 17px;
  color: black;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  border-radius: 15px;
  background-color: white;
  /* border: 2px solid #dbdbdb; */
  border-right: 2px solid #dbdbdb;
  border-top: 2px solid #dbdbdb;
  border-bottom: 2px solid #dbdbdb;
  border-left: 2px solid #dbdbdb;
  white-space: nowrap;
  margin-top: 5px;
}

.ribon-information-mobile-v2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 20px;
  padding-left: 10px;
  padding-right: 10px;
  color: black;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  border-radius: 15px;
  background-color: white;
  /* border: 2px solid #dbdbdb; */
  border-right: 2px solid #dbdbdb;
  border-top: 2px solid #dbdbdb;
  border-bottom: 2px solid #dbdbdb;
  border-left: 2px solid #dbdbdb;
  white-space: nowrap;
  margin-top: 0px;
}

.ribon-information-mobile-2-lines {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: auto;
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: black;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  border-radius: 15px;
  background-color: white;
  /* border: 2px solid #dbdbdb; */
  border-right: 2px solid #dbdbdb;
  border-top: 2px solid #dbdbdb;
  border-bottom: 2px solid #dbdbdb;
  border-left: 2px solid #dbdbdb;
  white-space: nowrap;
  margin-top: 5px;
  margin-right: 5px;
}

.check-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 40px;

  /* font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  background-color: white;
  border: 2px solid #dbdbdb; */
}

.title-ribon {
  margin-left: 10px;
}

.tab-content {
  width: 100%;
  height: 200px;
}

.tab-content-bis {
  width: 100%;
  height: 200px;
  margin-top: 20px;
}

.tab-content-bis-mobile-v2 {
  width: 100%;
  height: 200px;
  margin-top: 10px;
}

.tab-container-white {
  background-color: white;
  width: 100%;
  height: auto;
  box-shadow: 4px 4px 15px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.tab-container-white-v2 {
  width: 90%;
  height: auto;
  /* box-shadow: 4px 4px 15px 1px rgba(0, 0, 0, 0.25); */
  /* border-radius: 5px; */
  display: flex;
  flex-direction: column;
  padding-left: 60px;
}

.tab-container-white-v2-mobile {
  width: 100%;
  height: auto;
  /* box-shadow: 4px 4px 15px 1px rgba(0, 0, 0, 0.25); */
  /* border-radius: 5px; */
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 15px;
}

.tab-container-white-mobile {
  background-color: white;
  width: 120%;
  height: auto;
  box-shadow: 4px 4px 15px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: -20px;
}

.tab-container-white-mobile-bis {
  background-color: white;
  width: 110%;
  height: auto;
  box-shadow: 4px 4px 15px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
}

.tab-container-white-mobile-tris {
  background-color: white;
  width: 100%;
  height: auto;
  box-shadow: 4px 4px 15px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
}

.tab-container-white-desktop {
  background-color: white;
  width: 100%;
  height: auto;
  box-shadow: 4px 4px 15px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: -0px;
}

.tab-container-white-mobile2 {
  background-color: white;
  width: 120%;
  height: auto;
  box-shadow: 4px 4px 15px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: -20px;
  padding-bottom: 80px;
}

.tab-container-title {
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  margin-left: 0px;
  padding-top: 20px;
  margin-top: 10px;
}

.tab-container-title-2B {
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  margin-left: 40px;
  padding-top: 20px;
  margin-top: 10px;
}

.tab-container-title-2C {
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  margin-left: 30px;
  padding-top: 20px;
  margin-top: 10px;
}

.tab-container-title-mobile {
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  margin-left: 10px;
  padding-top: 10px;
  margin-top: 10px;
}

.tab-container-title-mobile-3 {
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  margin-left: 10px;
  padding-top: 10px;
  margin-top: 0px;
}

.tab-container-title-mobile-4 {
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  margin-left: 20px;
  padding-top: 10px;
  margin-top: 0px;
}

.tab-container-subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  margin-left: 30px;
  padding-top: 12px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.tab-container-subtitle-mobile {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.tab-container-title2 {
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  margin-left: 0px;
  padding-left: 100px;
  padding-top: 25px;
  width: 100%;
}

.tab-container-title3 {
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  margin-left: 0px;
  /* padding-left: 100px; */
  padding-top: 25px;
  width: 100%;
  text-align: center;
}

.tab-container-title3-mobile-v2 {
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  margin-left: 0px;
  /* padding-left: 100px; */
  padding-top: 10px;
  width: 100%;
  text-align: center;
}

.input-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: black;
}

.datepickerborder {
  height: 30px;
}

.custom-border {
  border-color: grey !important;
}

.input-label-mobile {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: black;
  width: 90%;
}

.input-label-top {
  font-weight: 400;
  font-size: 17px;
  line-height: 18px;
  color: #645f5f;
  margin-top: 20px;
}

.input-label-uploader {
  font-weight: 400;
  font-size: 17px;
  line-height: 18px;
  margin-left: 35px;
  color: #645f5f;
}

.input-label-2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #645f5f;
  margin-left: 40px;
  margin-bottom: 5px;
}

.input-label-container {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  margin-top: 10px;

  /* width: 300px; */
}

.input-label-container-mobile-v2 {
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  margin-top: 10px;

  /* width: 300px; */
}

.input-label-container-v2 {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  margin-top: 10px;

  /* width: 300px; */
}

.input-label-container-v3 {
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  margin-top: 10px;

  /* width: 300px; */
}

.input-label-container2 {
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  margin-top: 10px;

  /* width: 300px; */
}

.addclient-item-wrap-left {
  width: 50%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  color: grey;
}

.addclient-item-wrap-left-mobile {
  width: 40%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  color: grey;
}

.addclient-items-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.addclient-item-wrap-right {
  width: 50%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.addclient-item-wrap-right-mobile {
  width: 60%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
}

.input-label-container-newdesign {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  margin-top: 10px;
  width: 100%;
}

.input-label-container-newdesign-nomargin {
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  margin-top: 10px;
  width: 100%;
}

.input-label-container-mobile {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  margin-top: 10px;

  /* width: 300px; */
}

.input-label-container-mobile-bis {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  margin-top: 10px;
  width: 100%;

  /* width: 300px; */
}

.input-label-container-textfield {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  margin-top: 28px;

  /* width: 300px; */
}

.input-label-container-notes {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-top: 0px;
}
.tab-form-container-addclient {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  align-self: flex-start;
}

.tab-form-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  align-self: flex-start;
}

.tab-form-container-mobile-v3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  align-self: flex-start;
  width: 100%;
}

.tab-form-container-mobile-v2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: flex-start;
}

.tab-form-container-viewmodule {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* margin-top: 20px; */
  /* align-self: flex-start; */
  margin-top: 20px;
  margin-bottom: 20px;
}

.tab-form-container-biopsycho {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  align-self: flex-start;
  padding-left: 10px;
}

.tab-form-container-bottom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  align-self: flex-start;
  margin-bottom: 50px;
}

.tab-form-container-mobile-v2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0px;
  align-self: flex-start;
  width: 100%;
}

.tab-form-container-tab {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  align-self: flex-start;
}

.tab-form-container-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.tab-form-container-bis {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  align-self: flex-start;
  margin-left: 40px;
}

.tab-form-container-for-notes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  align-self: flex-start;
  width: 100%;
  /* background-color: pink; */
}

.tab-form-container-for-notes-mobile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0px;
  align-self: flex-start;
  width: 100%;
}

.tab-form-container-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 20px;
  align-self: flex-start;
}

.tab-form-container4 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  align-self: center-start;
}

.tab-form-container-centered {
  display: flex;
  height: auto;
  width: 100%;
  padding-top: 30px;
  margin-bottom: 20px;
  padding-right: 30px;
  align-items: center;
  justify-content: center;
}

.tab-form-container-line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  align-items: center;
}

.tab-form-container-handover {
  width: 95%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 10px;
}

.tab-form-container-red {
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #ffe7e7;
  border-radius: 15px;
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 30px;
}

.tab-form-container-blue {
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #5fbbfc;
  border-radius: 15px;
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 30px;
}

.tab-form-container-fied {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.datepicker-element {
  width: 150px;
  height: 40px;
  border: 0.5px solid #a5a2a2;
  border-radius: 5px;
  margin-top: 2px;
  padding-left: 10px;
}

.PhoneInputInput {
  width: 150px;
  height: 40px;
  border: 0.5px solid #a5a2a2;
  border-radius: 5px;
}

.button-container-form {
  /* position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; */

  width: 100%;
  display: flex;
  height: 80px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button-container-form-gi {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  width: 100%;
  display: flex;
  height: 80px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button-container-form-gi-mobile {
  position: fixed;
  bottom: 10px;
  right: 0px;
  z-index: 1000;
  width: 100%;
  display: flex;
  height: 80px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-top: 20px;
  margin-bottom: 5px;
}

.block-input {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.block-input-line2 {
  display: flex;
  flex-direction: row;
}

.formLine {
  width: 80%;
  height: 50px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid black;
}

.formLine60 {
  width: 60%;
  height: 50px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid black;
}

.formLineVersion2 {
  width: 100%;
  height: 50px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid black;
}

.formLine-mobile-version {
  width: 90%;
  height: 50px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid black;
}

.formLine-mobile-version2 {
  width: 100%;
  height: 50px;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid black;
}

.formLineMobile2 {
  width: 90%;
  height: 50px;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid black;
}

.title-line-mobile-bis {
  margin-top: 15px;
  width: 90%;
  height: 20px;
  /* margin-left: 20px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 5px;
  position: relative;
  padding-left: 10px;
  border-bottom: 1px solid #9a9797;
}

/* .title-line-mobile-bis::before {
  content: "";
  position: absolute;
  left: 10px; 
  bottom: 0;
  width: calc(
    100% - 10px
  ); 
  border-bottom: 1px solid #9a9797;
} */

.formLineMobile3 {
  width: 90%;
  height: 50px;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid black;
}

.formLineTitle {
  width: 45%;
  height: 50px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  margin-top: 30px;
  margin-bottom: 30px;
}

.formLineTitleMobile2 {
  width: 90%;
  height: 50px;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  margin-top: 30px;
  margin-bottom: 0px;
}

.formLineTitle2 {
  width: 60%;
  height: 50px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  margin-top: 30px;
  margin-bottom: 30px;
}

.formLineTitle3 {
  width: 57%;
  height: 50px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  margin-top: 30px;
  margin-bottom: 30px;
}

.title-line {
  margin-top: 15px;
  width: 60%;
  height: 20px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #9a9797;
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 5px;
}

.title-line-desktop {
  margin-top: 15px;
  width: 80%;
  height: 20px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #9a9797;
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 5px;
}

.title-line-desktop-60 {
  margin-top: 15px;
  width: 60%;
  height: 20px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #9a9797;
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 5px;
}

.title-line-desktop-80 {
  margin-top: 15px;
  width: 80%;
  height: 20px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #9a9797;
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 5px;
}
.title-line-desktop-80-bis {
  margin-top: 15px;
  width: 80%;
  height: 20px;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #9a9797;
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 5px;
}

.title-line-desktop-mobile {
  margin-top: 15px;
  width: 90%;
  height: 20px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #9a9797;
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 5px;
}

.title-line-mobile {
  margin-top: 15px;
  width: 90%;
  height: 20px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #9a9797;
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 5px;
}

/* .title-line-mobile-bis {
  margin-top: 15px;
  width: 90%;
  height: 20px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #9a9797;
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;  
  line-height: 14px;
  padding-bottom: 5px;
} */

.title-line-alert {
  margin-top: 15px;
  width: 100%;
  height: 20px;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #9a9797;
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 5px;
}

.blue-alert {
  /* background-color: #007bff;  */
}

.orange-alert {
  /* background-color: #ffa500;  */
}

.formLineVersion2 {
  /* Autres styles pour la ligne complète */
}

/* .formLineBlock1Version2 {
  padding: 5px; 
  border-radius: 4px; 
} */

/* .alertTextColor {
  color: #ffffff; 
} */

.title-line-alert-mobile {
  margin-top: 15px;
  width: 100%;
  height: 20px;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #9a9797;
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 5px;
}

.square-button {
  width: 43px;
  height: 43px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  cursor: pointer;
}

.square-button-mobile {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  cursor: pointer;
}

.square-button-2 {
  width: 43px;
  height: 43px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  cursor: pointer;
  margin-left: 20px;
}

.square-button-2-mobile-v2 {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  cursor: pointer;
  margin-left: 5px;
}

.square-button-2-mobile {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  cursor: pointer;
  margin-left: 20px;
}

.square-button-small {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.view-button-color {
  background: #6850ff;
}

.download-button-color {
  background: #c70d0d;
}

.check-button-color {
  background: #168f02;
}

.print-button-color {
  background: #bd06ab;
}

.delete-button-color {
  background: red;
}

.mail-button-color {
  background: #e2b208;
}

.review-button-color {
  background: #ff6f50;
}

.formLineBlockAll {
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

/* .blue-alert {
  background-color: #0bb4fd;
} */

/* .orange-alert {
  background-color: #fdaa0b;
} */

.red-alert {
  /* background-color: #fd0b0b; */
}

.alertTextColor {
  color: black;
  font-size: 16px;
  font-weight: 600;
}

.alertTextColorMobile {
  color: white;
  font-size: 14px;
  font-weight: 400;
}

.formLineBlock1 {
  width: 40%;
  height: 100%;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: black;
}

.formLineBlock2 {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  /* color: black; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.formLineBlock1Mobile2 {
  width: 40%;
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: black;
}

.formLineBlock1Mobile3 {
  width: 30%;
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  /* color: black; */
}

.formLineBlock2mobile2 {
  width: 35%;
  height: 100%;
  font-size: 10;
  display: flex;
  align-items: center;
  /* color: black; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.formLineBlock2mobile2bis {
  width: 35%;
  height: 100%;
  font-size: 8;
  display: flex;
  align-items: center;
  /* color: black; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.formLineBlock2mobile4 {
  width: 35%;
  height: 100%;
  font-size: 10;
  display: flex;
  align-items: center;
  /* color: black; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.formLineBlock2mobile5 {
  width: 30%;
  height: 100%;
  font-size: 10;
  display: flex;
  align-items: center;
  /* color: black; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.formLineBlock3 {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
  padding-left: 10px;
}

.formLineBlock1Version2 {
  width: 10%;
  height: 100%;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: black;
}

.formLineBlock2Version2 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  /* color: black; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.formLineBlock3Version2 {
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
  padding-left: 10px;
}

.formLineBlock3Version2v2 {
  width: 5%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
  padding-left: 10px;
}

.formLineBlock1-mobile {
  width: 35%;
  height: 100%;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: black;
}

.formLineBlock2-mobile {
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;
  /* color: black; */
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.formLineBlock3-mobile {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding-left: 10px;
}

.title-block1 {
  padding-left: 0px;
  width: 40%;
  height: 100%;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.title-block1-mobile {
  padding-left: 10px;
  width: 35%;
  height: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.title-block2-mobile {
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-block3-mobile {
  padding-left: 0px;
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
  justify-content: center;
}

.title-block1-mobile-bis {
  padding-left: 0px;
  width: 35%;
  height: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.title-block1-mobile-tris {
  padding-left: 10px;
  width: 30%;
  height: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.title-block2 {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0px;
}

.title-block3 {
  padding-left: 15px;
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
}

.title-block4 {
  padding-left: 10px;
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
}

/* .title-block1-mobile {
  padding-left: 10px;
  width: 35%;
  height: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
} */

.grey-form {
  background-color: #e1e1e1;
}

.light-grey-form {
  background-color: #f5f2f2;
}

.signed-check {
  display: flex;
  flex-direction: row;
}

.text-signed {
  font-weight: 500;
  font-size: 16px;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  line-height: 16px;
}

.formLineMedium {
  width: 75%;
  height: 50px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.formLineMedium-60 {
  width: 60%;
  height: 50px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.formLineMedium-medium-2 {
  width: 90%;
  height: 50px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.formLineMedium2 {
  width: 75%;
  height: 50px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.formLineMedium2-10 {
  width: 75%;
  height: 50px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.roi-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.roi-container-mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 15px;
}

.roi-container-2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 15px;
}

.roi-container-2bis {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 15px;
}

.formLineMedium-Mobile {
  width: 90%;
  height: 50px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.formLineMedium-Mobile2 {
  width: 90%;
  height: 50px;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title-line-medium {
  margin-top: 30px;
  width: 75%;
  height: 20px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #9a9797;
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 5px;
}

.title-line-medium-mobile {
  margin-top: 30px;
  width: 75%;
  height: 20px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #9a9797;
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 5px;
}

.title-line-medium-6 {
  margin-top: 30px;
  width: 90%;
  height: 20px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #9a9797;
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 5px;
}

.title-line-medium-roi {
  margin-top: 5px;
  width: 75%;
  height: 20px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #9a9797;
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 5px;
}

.title-line-medium-roi-v2 {
  margin-top: 5px;
  width: 75%;
  height: 20px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border-bottom: 1px solid #9a9797; */
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* padding-bottom: 5px; */
}

.title-line-medium-roi-60 {
  margin-top: 5px;
  width: 60%;
  height: 20px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border-bottom: 1px solid #9a9797; */
  color: #807f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 0px;
}

.formLine2Block1 {
  width: 45%;
  height: 100%;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.formLine2Block1-mobile {
  width: 50%;
  height: 100%;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.formLine2Block2 {
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
}

.formLine2Block2bis {
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
}

.formLine2Block3 {
  width: 27%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.formLineRoiBlock1 {
  width: 39%;
  height: 100%;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  /* border: 1px solid red; */
}

.formLineRoiBlock1-mobile {
  width: 50%;
  height: 100%;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  /* border: 1px solid red; */
}

.formLineRoiBlock2-mobile {
  width: 12%;
  height: 100%;
  display: flex;
  align-items: center;
  /* border: 1px solid blue; */
}

.formLineRoiBlock3-mobile {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  /* border: 1px solid red; */
}

.formLineRoiBlock2 {
  width: 12%;
  height: 100%;
  display: flex;
  align-items: center;
  /* border: 1px solid blue; */
}

.formLineRoiBlock2bis {
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
}

.formLineRoiBlock2tris {
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  /* border: 1px solid blue; */
}

.formLineRoiBlock3 {
  width: 19%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  /* border: 1px solid red; */
}

.title-medium-block1 {
  padding-left: 10px;
  width: 45%;
  height: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.title-medium-block2 {
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
}

.title-medium-block2bis {
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
}

.title-medium-block3 {
  padding-left: 15px;
  width: 27%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
}

.title-roi-block1 {
  /* padding-left: 10px; */
  width: 39%;
  height: 100%;
  font-weight: 400;
  display: flex;
  /* background-color: gainsboro; */
  align-items: center;
}

.title-treatment-block1 {
  /* padding-left: 10px; */
  width: 45%;
  height: 100%;
  font-weight: 400;
  display: flex;
  /* background-color: gainsboro; */
  align-items: center;
}

.title-roi-block1bis {
  /* padding-left: 10px; */
  width: 12%;
  height: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.title-roi-block2 {
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  /* background-color: gainsboro; */
}

.title-treatment-block2 {
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
  /* background-color: gainsboro; */
}

.title-roi-block2bis {
  width: 15%;
  height: 100%;
  /* display: flex;
  align-items: center; */
  text-align: center;

  height: auto;
}

.title-treatment-block2bis {
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
}

.title-roi-block3 {
  /* padding-left: 15px; */
  width: 19%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  /* background-color: gainsboro; */
}

.title-treatment-block3 {
  /* padding-left: 15px; */
  width: 27%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 0px;
  /* background-color: gainsboro; */
}

.margin-title {
  margin-top: 30px;
}

.separator {
  height: 50px;
  width: 50px;
}

.add-round-button {
  width: 35px;
  height: 35px;
  background-color: #1976d2;
  color: white;
  border-radius: 17.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 34px;
  line-height: 28px;
  padding-bottom: 9px;
  margin-left: 10px;
  cursor: pointer;
}

.add-round-button-small {
  width: 35px;
  height: 35px;
  background-color: #1976d2;
  color: white;
  border-radius: 17.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 34px;
  line-height: 28px;
  padding-bottom: 9px;
  margin-left: 10px;
  cursor: pointer;
}

.add-round-button-small-mobile {
  width: 45px;
  height: 45px;
  background-color: #1976d2;
  color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 34px;
  line-height: 28px;
  padding-bottom: 0px;
  margin-left: 0px;
  cursor: pointer;
}

.add-round-button-mobile {
  width: 35px;
  height: 35px;
  /* background-color: #1976d2; */
  color: white;
  border-radius: 17.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  padding-bottom: 0px;
  margin-left: 10px;
  cursor: pointer;
}

/* .uploader-roi {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.form-line-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
}

.form-line-button-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
}

.form-line-button-column-center {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0px;
  margin-left: 10px;
}

.form-line-button-title {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0px;
}

.form-line-button-title-column {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0px;
}

.form-line-button-title-column-2 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0px;
}

.form-line-button-title-column-3 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 0px;
  margin-left: 12px;
}

.form-line-button-title-column-4 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  margin-left: 0px;
}

.simple-title-container {
  width: fit-content;
  height: 50px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  padding-left: 15px;
  padding-right: 15px;
}

.session-line {
  width: 70%;
  height: 60px;
  background-color: #f5f2f2;
  border-radius: 20px;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}

.session-line-block1 {
  width: 28%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 14px;
  font-weight: 500;
  font-size: 18px;
}

.session-line-block2 {
  width: 26%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 500;
  font-size: 18px;
}

.session-line-block3 {
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 500;
  font-size: 18px;
}

.session-line-block4 {
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 500;
  font-size: 18px;
}

.session-line-block5 {
  width: 16%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 500;
  font-size: 15px;
}

.title-session {
  font-weight: 400;
  font-size: 15px;
  color: #807f7f;
  padding-left: 5px;
  padding-bottom: 8px;
}

.title-session2 {
  font-weight: 400;
  font-size: 15px;
  color: #807f7f;
  padding-left: 5px;
  padding-bottom: 8px;
  margin-top: 40px;
}

.text-check {
  padding-left: 5px;
  line-height: 18px;
}

.img-div {
  margin-right: 5px;
}

.button-session {
  width: 120px;
  height: 25px;
  background: #1976d2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.rectangle-container {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-top: 10px;
}

.yes-no-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-left: 30px; */
  padding-left: 30px;
  margin-top: 20px;
  /* border: 1px solid black; */
  padding: 10px;
  /* margin-left: 10px;
  margin-right: 10px; */
  border-radius: 10px;
  /* margin-left: 20px; */
}

.yes-no-container-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 30px;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
}

.yes-no-container-column-mobile-v2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
}

.yes-no-container-column-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin-left: 30px; */
  padding-left: 0px;
  margin-top: 20px;
  /* border: 1px solid black; */
  padding: 10px;
  /* margin-left: 10px;
  margin-right: 10px; */
  border-radius: 10px;
  /* margin-left: 20px; */
}

.yes-no-container-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-left: 30px; */
  padding-left: 30px;
  margin-top: 20px;
  /* border: 1px solid black; */
  padding: 10px;
  /* margin-left: 10px;
  margin-right: 10px; */
  border-radius: 10px;
  /* margin-left: 20px; */
}

.yes-no-container-include {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-left: 30px; */
  padding-left: 30px;
  margin-top: 20px;
  border: 1px solid black;
  padding: 10px;
  margin-left: 30px;
  /* margin-left: 10px;
  margin-right: 10px; */
  border-radius: 10px;
}

.yes-no-checkbox-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.yes-no-checkbox-container-50prcent {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.yes-no-checkbox-container-40prcent {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.yes-no-checkbox-container-100prcent {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.yes-no-container-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 30px;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox-container-margin {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  margin-left: 10px;
}

.checkbox-container-margin-bis {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 5px;
  padding-right: 10px;
}
.checkbox-container-margin-tris {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
  margin-left: 10px;
  margin-bottom: 0px;
  padding-right: 10px;
}

.sentence-yes-no {
  font-weight: 400;
  font-size: 17px;
  color: black;
  padding-right: 20px;
  /* padding-top: 25px;
  padding-bottom: 10px; */
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 20px;
}

.sentence-yes-no-5 {
  font-weight: 400;
  font-size: 17px;
  color: black;
  padding-right: 20px;
  /* padding-top: 25px;
  padding-bottom: 10px; */
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 0px;
  margin-bottom: 5px;
}

.sentence-yes-no-50prcent {
  width: 30%;
  font-weight: 400;
  font-size: 17px;
  color: black;
  margin-right: 20px;
  /* padding-top: 25px;
  padding-bottom: 10px; */
  display: flex;
  align-items: center;
  height: 100%;
}

.sentence-yes-no-60prcent {
  width: 60%;
  font-weight: 400;
  font-size: 17px;
  color: black;
  margin-right: 20px;
  /* padding-top: 25px;
  padding-bottom: 10px; */
  display: flex;
  align-items: center;
  height: 100%;
}

.text-block {
  font-weight: 400;
  font-size: 17px;
  color: black;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.text-block2 {
  font-weight: 500;
  font-size: 17px;
  color: black;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
  margin-right: 30px;
}

.text-block3 {
  font-weight: 400;
  font-size: 17px;
  color: black;

  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 30px;
  margin-right: 30px;
}

.tab-form-container2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.tab-form-container3 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
  /* background-color: yellow; */
}

.tab-form-container5 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.tab-form-container5Top {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.tab-form-container5-mobile {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.tab-form-container5-mobile95 {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.tab-form-container5-mobile100 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.tab-form-container5-mobile100-bis {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.upload-content-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.upload-content-container-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.files-container {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 500px;
  height: auto;
  border: 2px dashed #1475cf;
}

.file-line {
  width: 100%;
  height: 50px;

  display: flex;
  flex-direction: row;
}

.file-line-block1 {
  width: 10%;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-line-block2 {
  width: 50%;

  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: left;
}

.file-line-block2bis {
  width: 20%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  justify-content: left;
}
.file-line-block3 {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.file-line-block4 {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.billing-rectangle {
  width: 250px;
  height: 90px;
  background: #ffffff;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);
  margin-left: 20px;
  /* margin-top: 25px; */
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 35px;
}

.rectangle-empty {
  width: 250px;
  height: 90px;
  background: #ffffff;
  margin-left: 20px;
  /* margin-top: 25px; */
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.billing-rectangle-title-1 {
  font-weight: 500;
  font-size: 26px;
}

.billing-item-line {
  width: 100%;
  height: 55px;
  background-color: #e4e3e3;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
}

.billing-item-line-block-1 {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 20px;
}

.billing-item-line-block-2 {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label-billing-item {
  margin-left: 10px;
}

.note-container {
  margin-top: 0px;
  width: 98%;
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note-container-v2 {
  margin-top: 0px;
  width: 98%;
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: yellow;
}

.note-container-mobile {
  margin-top: 0px;
  width: 98%;
  height: 260px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.group-note-container {
  margin-top: 0px;
  width: 98%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note-container-block-1-mobile {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 5px;
  margin-left: 4px;
}

.group-note-container-block-1 {
  width: 8.5%;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.note-container-block-1 {
  width: 8%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.note-container-block-2 {
  width: 2.5%;
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note-container-block-3 {
  width: 89%;
  height: 240px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.group-note-container-block-2 {
  width: 2.5%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note-container-block-3-mobile {
  width: 100%;
  height: 80%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-left: 5px;
}

.group-note-container-block-3 {
  width: 89%;
  height: 280px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.group-note-container-block-4 {
  width: 86.5%;
  height: 280px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.notes-container-global {
  display: flex;
  flex-direction: column;
  /* width: 1250px; */
  width: 100%;
}

.notes-container-global-v3 {
  display: flex;
  flex-direction: column;
  /* width: 1250px; */
  width: 90%;
}

.notes-container-global-mobile {
  display: flex;
  flex-direction: column;
  /* width: 1250px; */
  width: 900px;
}

.notes-container {
  display: flex;
  flex-direction: column;
  width: 95%;
}

.client-profil-add-client-button {
}

.notes-button-container {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.notes-button-container-center {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notes-button-container-mobile {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-and-time-chip {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  border-radius: 5px;
  background-color: #257dd4;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  text-align: center;
  padding-bottom: 3px;
  padding-top: 3px;
  margin-top: 3px;
}

.date-and-time-chip-mobile {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  border-radius: 5px;
  background-color: #257dd4;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  text-align: center;
  padding-bottom: 3px;
  padding-top: 3px;
  margin-top: 3px;
  margin-right: 5px;
}

.note-type-chip {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  border-radius: 5px;
  background-color: #08843c;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 10px;
  text-align: center;
  padding-bottom: 3px;
  padding-top: 3px;
  margin-top: 3px;
}

.note-container-line-1 {
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: row;
}

.note-container-line-1-mobile {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
}

.note-container-line-1-mobile-bis {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  padding-left: 5px;
}

.note-container-line-2-mobile {
  width: 100%;
  min-height: 60%;
  overflow-y: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 10px;
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  word-wrap: break-word;
}

.note-container-line-0-mobile {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 2px;
}

.notes-wrapper {
  max-width: 98%;
  overflow-x: auto;
}

.notes-wrapper-v2 {
  max-width: 98%;
  overflow-x: auto;
  height: 280px;
}

.notes-wrapper-mobile {
  max-width: 98%;
  overflow-x: auto;
  margin-bottom: 20px;
}

.note-container-line-2 {
  width: 100%;
  min-height: 78%;
  overflow-y: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  word-wrap: break-word;
}

.note-container-line-2bis-mobile {
  width: 100%;
  min-height: 60%;
  overflow-y: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  word-wrap: break-word;
}

.group-note-container-line-1 {
  width: 100%;
  height: 17%;
  display: flex;
  flex-direction: row;
}

.group-note-container-line-2 {
  width: 100%;
  max-height: 66%;
  overflow-y: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  word-wrap: break-word;
}

.group-note-container-line-50 {
  width: 50%;
  min-height: 100%;
  overflow-y: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
}

.group-container-line1-block1 {
  width: 33%;
  height: 100%;
  border-right: 0.5px solid #bcb5b5;
}

.group-container-line1-block4 {
  width: 67%;
  height: 100%;
  border-right: 0.5px solid #bcb5b5;
}

.group-container-line1-block1-v2 {
  width: 66%;
  height: 100%;
  border-right: 0.5px solid #bcb5b5;
}

.group-container-line1-block1-v3 {
  width: 100%;
  height: 100%;
  border-right: 0.5px solid #bcb5b5;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  vertical-align: middle;
}
.group-container-line1-block2 {
  width: 33%;
  height: 100%;
  border-left: 0.5px solid #bcb5b5;
  padding: 5px;
}

.handover-container-line1-block2 {
  width: 25%;
  height: 100%;
  border-left: 0.5px solid #bcb5b5;
  padding: 5px;
}

.note-container-line1-block1 {
  width: 16%;
  height: 100%;
  border-right: 0.5px solid #bcb5b5;
}

.note-container-line1-block1bis {
  width: 22%;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
}

.note-container-line1-block1-mobile {
  width: 30%;
  height: 100%;
  border-right: 0.5px solid #bcb5b5;
}

.note-container-line1-block1bis-mobile {
  width: 60%;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
}

.note-container-line1-block1bis-mobile-fileattached1 {
  width: 30%;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
}

.note-container-line1-block1bis-mobile-fileattached2 {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.note-container-line1-block2-mobile {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  border-left: 0.5px solid #bcb5b5;
}

.note-container-line1-block1bisDouble {
  display: flex;
  width: 44%;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
}

.note-container-line1-block2 {
  display: flex;
  flex-direction: column;
  width: 28%;
  height: 100%;
  border-left: 0.5px solid #bcb5b5;
}

.note-container-line1-block3-bis {
  display: flex;
  flex-direction: column;
  width: 34%;
  height: 100%;
  border-left: 0.5px solid #bcb5b5;
}

.centre-block {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid #bcb5b5;
  font-weight: 500;
  font-size: 16px;
}

.content-cell-container {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-cell-container {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
}

.title-cell-container-mobile-2 {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  font-size: 10px;
}

.title-cell-container-mobile {
  width: 100%;
  font-size: 12px;
  display: flex;
  align-items: center; /* Align vertically */
  justify-content: space-between; /* Put space between title and content */
}

.title-text {
  font-weight: 400;
}

.content-text {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-cell-container2 {
  width: 100%;
  font-size: 10px;
  font-weight: 400;
}

.left-block {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-bottom: 0.5px solid #bcb5b5;
  font-weight: 500;
  font-size: 16px;
  padding: 5px;
}

.left-block-mobile-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 0.5px solid #bcb5b5;
  font-weight: 500;
  font-size: 9px;
  padding: 2px;
}

.left-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 0.5px solid #bcb5b5;
  font-weight: 500;
  font-size: 16px;
  padding-left: 15px;
}

.centre-block2 {
  border-bottom: 0.5px solid #bcb5b5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub-block-2 {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 5px;
}

.sub-block-3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 10px;
}

.modal-title {
  font-weight: 400;
  font-size: 18px;
  margin-left: 35px;
  color: black;
}

.modal-title-small {
  font-weight: 400;
  font-size: 14px;
  margin-left: 35px;
  color: white;
}

.modal-title2 {
  font-weight: 400;
  font-size: 18px;
  margin-left: 35px;
  color: white;
  display: flex;
  flex-direction: column;
}

.line1-modal {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  /* background-color: yellow; */
  background-color: #1f55e8;
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
  /* border-bottom: 1px solid #bcb5b5; */
}

.line1-modal-newdesign {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: #1f55e8;
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
  /* border-top-left-radius: 19px;
  border-top-right-radius: 19px; */
  /* border-bottom: 1px solid #bcb5b5; */
}

.line1-modal-newdesign-bis {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 10%;
  /* background-color: #1f55e8; */
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
  /* border-top-left-radius: 19px;
  border-top-right-radius: 19px; */
  /* border-bottom: 1px solid #bcb5b5; */
}

.line1-modal-percent {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 10%;
  max-height: 70px;
  /* background-color: #1f55e8; */
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
  /* border-bottom: 1px solid #bcb5b5; */
}

.line1-modal-red {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: #c01515;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  /* border-bottom: 1px solid #bcb5b5; */
}

.line2-modal {
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.line2-column-modal {
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.line2-column-modal-v2 {
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-left: 25px;
  padding-right: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.line2-column-modal-bis {
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.line2-column-modal-bis-left {
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.line2-column-modal-slider {
  width: 100%;
  height: auto;
  padding-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.line2-column-modal-handover {
  width: 100%;
  height: auto;
  padding-top: 0px;
  padding-left: 5px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.line3-modal {
  width: 100%;
  height: 70px;
  /* padding-top: 20px; */
  padding-left: 0px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.line3-modal-mobile {
  width: 100%;
  height: 70px;
  /* padding-top: 20px; */
  padding-left: 0px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.line3-modal-bis {
  width: 100%;
  height: 70px;
  /* padding-top: 20px; */
  padding-left: 0px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.line3-modal-nopad {
  width: 100%;
  height: 70px;
  /* padding-top: 20px; */
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.line3-modal-mobile {
  width: 100%;
  height: auto;
  /* padding-top: 20px; */
  padding-left: 0px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.line3-modal-mobile-v2 {
  width: 100%;
  height: auto;
  /* padding-top: 20px; */
  padding-left: 0px;
  padding-right: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.line3-modal-mobile-bis {
  width: 100%;
  height: auto;
  /* padding-top: 20px; */
  padding-left: 20px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

.line3-modal-view {
  width: 100%;
  height: 70px;
  /* padding-top: 20px; */
  padding-left: 15px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.line4-modal {
  width: 100%;
  height: 70px;
  /* padding-top: 20px; */
  padding-left: 10px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.line4-modal-newdesign {
  width: 100%;
  height: 70px;
  /* padding-top: 20px; */
  padding-left: 10px;
  padding-right: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.titleInput {
  font-size: 12px;
  color: grey;
}
.contentInput {
  font-size: 16px;
  padding-right: 30px;
  color: rgb(0, 0, 0);
  text-align: justify;
}

.viewFooter {
  height: 60px;
}

.line4-modal-percent {
  width: 100%;
  height: 15%;
  /* max-height: 100px; */
  /* padding-top: 20px; */
  padding-left: 10px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #ccc;
}

.line4-modal-percent-bis {
  width: 100%;
  height: 10%;
  padding-left: 10px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white; /* Fond blanc */
  border-bottom-left-radius: 19px;
  border-bottom-right-radius: 19px;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
}

.line4-modal-percent-bis.background-blue {
  background-color: rgba(0, 123, 255, 0.2); /* Bleu clair avec 20% d'opacité */
}

.bottom-line-modal {
  width: 100%;
  height: 100px;
  /* padding-top: 20px; */
  padding-left: 10px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.line6-modal {
  width: 100%;
  height: 100px;
  /* padding-top: 20px; */
  padding-left: 10px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.line4-modal-centered {
  width: 100%;
  height: 70px;
  /* padding-top: 20px; */

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.line3bis-modal {
  width: 100%;
  height: auto;
  /* padding-top: 20px; */
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.label-textfield {
  width: 100%;
  color: #645f5f;
  margin-left: 35px;
  margin-bottom: 5px;
}

.label-textfield2 {
  width: 100%;
  color: #645f5f;
  margin-left: 0px;
  margin-bottom: 5px;
}

.label-textfield-underline {
  font-size: 18px;
  width: 100%;
  color: #000000;
  font-weight: 500;
  padding-left: 20px;
  margin-bottom: 5px;
}

.label-textfield-handover {
  width: 100%;
  color: #645f5f;
  margin-left: 5px;
  margin-bottom: 5px;
}

.view-text {
  width: "100%";
  word-wrap: break-word;
  margin-bottom: 15px;
  white-space: pre-line;
}

.label-textfield-handover-view {
  width: 100%;
  color: black;
  margin-left: 0px;
  margin-bottom: 3px;
  font-weight: 600;
}

.textfield-and-label-container {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.textfield-and-label-container100 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.titleModalEditClient {
  margin-left: 10px;
  padding-top: 5px;
}

.sigCanvas {
  border: 1px solid black;
  border-radius: 10px;
  margin-left: 35px;
  margin-top: 25px;
  /* width: 50%; */
}

.signature-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.signature-container-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}

.signature-container-column-bis {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.right-signature-container {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.right-signature-container-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.signature-buttons-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signature-buttons-container-bis {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  justify-content: center;
}

.titlesignaturevalidation {
  width: 100%;
  height: 30%;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.titlesignaturevalidationleft {
  width: 100%;
  height: 30%;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-left: 20px;
}

.SubTitleBold {
  width: 100%;
  height: auto;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 20px;
}

.SubTitleBold-viewmodule {
  /* width: auto; */
  /* height: auto; */
  text-align: left;
  font-size: 18px;
  font-weight: 500;
}

.SubTitleBold4 {
  width: 100%;
  height: auto;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-right: 10px;
}

.SubTitleBold5 {
  width: 100%;
  height: auto;
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  margin-top: 20px;
  margin-bottom: 0px;
  padding-right: 10px;
}

.SubTitleBold2 {
  width: 100%;
  height: auto;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 20px;
}

.SubTitleBold3 {
  width: 100%;
  height: auto;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  margin-top: 25px;
  margin-bottom: 10px;
  padding-right: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid grey;
}

.block-text-check {
  margin-top: 20px;
  margin-bottom: 20px;
}

.block-text-check-mobile-v2 {
  margin-top: 10px;
  margin-bottom: 0px;
}

.block-text-check-bis {
  margin-top: 8px;
  margin-bottom: 0px;
  padding-left: 15px;
}

.block-text-check-leftm {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
}

.objectives-and-reviews-container {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  width: 100%;
}

.title-rating {
  margin-left: 35px;
  margin-top: 20px;
  margin-bottom: 3px;
  color: black;
  font-size: 12px;
}

.radio-group-container {
  margin-left: 30px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
}

.checkbox-line {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-container {
  margin-top: 20px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.table-line-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 80px;
}

.table-line-block {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 16.6%;
  height: 100%;
  border: 1px solid black;
  font-weight: 600;
}

.container-text-round-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-left: 35px;
  margin-top: 20px;
}

.text-for-round-button {
  font-weight: 500;
}

.summary-container {
  width: 100%;
  height: auto;
  border: 1px solid black;
  border-radius: 15px;
  margin-bottom: 20px;
}

.summary-top-container {
  width: 100%;
  padding: 10px;
}

.top-input-container {
  width: 50%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.top-input-container2 {
  width: 93%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.input-container-bis {
  margin-right: 20px;
}

.container-google-sso {
  width: 70%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 500px) {
  .container-google-sso {
    width: 90%; /* Lorsque la largeur de l'écran est inférieure à 400 pixels, la largeur sera de 90% */
  }
}

.google-icon {
  width: 25px;
  height: 25px;
  margin-right: 25px;
}

.circular-progress-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading-text {
  width: 100%;
  text-align: center;
}

.loading-container {
  display: flex;
  flex-direction: column;
}

.file-item {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-around;
  margin-left: 20px;
  width: 95%;
  margin-bottom: 3px;
}

.file-item-grey {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-around;
  /* margin-left: 10px;
  margin-right: 10px; */
  width: 100%;
  margin-bottom: 5px;
  height: "auto";
  /* background-color: rgba(202, 203, 203, 0.2); */
  border-radius: 5px;
  border-width: 1px;
  border-color: rgba(202, 203, 203, 0.5);
  border-style: solid;
  padding-top: 5px;
  padding-bottom: 5px;
}

.file-item2 {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-around;
  margin-left: 0px;
  width: 95%;
  margin-bottom: 3px;
  background-color: #dbdbdb;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.file-item2-v2 {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-around;
  margin-left: 0px;
  width: 95%;
  margin-bottom: 3px;
  background-color: #ececec;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2); */
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
  border-radius: 4px;
  margin-bottom: 20px;
}

.list-files-container {
  margin-top: 15px;
  margin-bottom: 20px;
  width: 95%;
}

.list-files-container-grey {
  margin-top: 15px;
  margin-bottom: 20px;
  width: 95%;
}

.line5-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}

.line5-modal-v2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}

.select-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  font-size: 14px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.select-title-v2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  font-size: 14px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.ul-container {
  display: flex;
  flex-direction: column;
}

.ul-container-v2 {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.a-container {
  display: flex;

  width: 100%;
}

.confirmation-delete {
  margin-top: 20px;
  width: 90%;
  text-align: center;
  margin-bottom: 20px;
}

.confirmation-delete-bis {
  margin-top: 0px;
  width: 90%;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}

.note-content-for-print {
  margin-top: 10px;
  width: 95%;
  height: auto;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}

.ellipsis2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}

.signature-validation-message {
  text-align: center;
  font-size: 12px;
  margin-top: 20px;
}

.signature-validation-message-bis {
  text-align: right;
  font-size: 12px;
  margin-top: 5px;
  padding-right: 10px;
  margin-bottom: 10px;
}

.text-block-2 {
  font-weight: 600;
  margin-left: 25px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.signature-pic {
  margin-left: 25px;
  margin-top: 30px;
  /* border: 1px solid black;
  border-radius: 20px;
  padding: 10px; */
}

.signature-pic-roi {
  margin-left: 25px;
  margin-top: 20px;
  border: 1px solid black;
  border-radius: 20px;
  padding: 30px;
  width: 300px;
  height: 200px;
}

.client-name-container {
  margin-left: 25px;
  font-weight: 600;
  font-size: 20px;
}

.viewBlock {
  display: "flex";
  flex-direction: column;
  padding-left: 30px;
  margin-top: 30px;
}

.top-title-view-modal {
  padding-left: 30px;
  font-weight: 600;
  font-size: 20px;
  margin-top: 15px;
  border-bottom: 1px solid grey;
  width: 100%;
  padding-bottom: 5px;
}

.client-name-container-centered {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}

.top-text-block-roi {
  font-size: 18px;
  margin-left: 30px;
  margin-right: 30px;
}

.signature-title {
  margin-left: 30px;
  margin-top: 15px;
}

@media print {
  .signature-pic-roi {
    visibility: visible !important;
    width: auto !important;
    height: auto !important;
    max-width: 100% !important;
    max-height: 100% !important;
  }
}

.error-discharge-message {
  margin-top: 10px;
  margin-left: 30px;
  color: red;
  font-weight: bold;
}

.error-discharge-message-mobile {
  margin-top: 10px;
  margin-left: 0px;
  padding-left: 5px;
  padding-right: 5px;
  color: red;
  font-weight: bold;
  text-align: center;
}

.radio-group-form-title {
  color: #000000;
}

.empty-content {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.objectives-list {
  width: 80%;
  border-radius: 5px;
  margin-left: 0px;
}

.objective-item {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #d6ebfb;
  padding: 10px;
  border-radius: 10px;
}

.title-objective-treatment {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 5px;
}

.text-container {
  word-wrap: break-word;
}

.objective-item-red {
  background-color: #ffa4a4;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}

.documents-container {
  margin: 20px;
}

.documents-container-mobile-v2 {
  margin: 0px;
}

.input-name-file {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.container-main-plan {
  /* margin-top: 40px; */
  width: 100%;
  /* margin-left: 2%; */
  border: 1px dashed black;
  padding-bottom: 30px;
  border-radius: 10px;
}

.container-main-plan-v2 {
  /* margin-top: 40px; */
  width: 100%;
  padding-left: 1.5%;
  padding-right: 1.5%;
  border: 1px dashed black;
  padding-bottom: 30px;
  border-radius: 10px;
}

.container-main-plan-mobile-3 {
  /* margin-top: 40px; */
  width: 100%;
  /* margin-left: 2%; */
  border: 1px dashed black;
  padding-bottom: 30px;
  border-radius: 10px;
}

.margin-bottom-2 {
  margin-bottom: 20px;
}

.tab-client-container {
}

.tab-client-container-mobile {
  width: 100%;
}

.tab-client-container-mobile-v2 {
}

.container-main-plan-mobile {
  margin-top: 40px;
  width: 100%;
  /* margin-left: 2%; */
  border: 2px dashed black;
  padding-bottom: 30px;
  border-radius: 10px;
}

.container-main-plan-mobile-with-padding {
  margin-top: 20px;
  width: 100%;
  /* margin-left: 2%; */
  border: 1px dashed black;
  padding-bottom: 30px;
  border-radius: 10px;
  padding-right: 10px;
}

.container-main-plan-mobile-bottom {
  margin-top: 40px;
  width: 100%;
  /* margin-left: 2%; */
  border: 2px dashed black;
  padding-bottom: 70px;
  border-radius: 10px;
}

.container-main-plan-blue {
  margin-top: 40px;
  width: 100%;
  margin-left: 0%;
  border: 1px dashed blue;
  padding-bottom: 30px;
  border-radius: 10px;
}

.container-main-plan-blue-mobile {
  margin-top: 40px;
  width: 100%;
  margin-left: 2%;
  border: 2px dashed blue;
  padding-bottom: 30px;
  border-radius: 10px;
}

.container-main-plan-mobile {
  margin-top: 10px;
  width: 100%;
  margin-left: 0%;
  border: 1px dashed black;
  padding-bottom: 30px;
  border-radius: 5px;
}

.container-main-plan-mobile-2 {
  margin-top: 0px;
  width: 100%;
  /* margin-left: 2%; */
  border: 1px dashed black;
  padding-bottom: 30px;
  border-radius: 10px;
}

.container-main-plan-mobile-v3 {
  margin-top: 0px;
  width: 100%;
  /* margin-left: 2%; */
  padding-bottom: 30px;
  border-radius: 10px;
}

.container-main-plan-bis {
  margin-top: 40px;
  width: 95%;
  margin-left: 2%;
  border: 2px dashed black;
  padding-bottom: 30px;
  border-radius: 10px;
  margin-bottom: 80px;
}

.container-main-plan-red {
  margin-top: 40px;
  width: 100%;
  margin-left: 0%;
  border: 2px dashed #ffe7e7;
  padding-bottom: 80px;
  border-radius: 10px;
  background-color: #ffe7e7;
  /* margin-bottom: 70px; */
}

.container-main-plan-red-bis {
  margin-top: 40px;
  width: 50%;
  margin-left: 0%;
  border: 2px dashed #ffe7e7;
  padding-bottom: 80px;
  border-radius: 10px;
  background-color: #ffe7e7;
  /* margin-bottom: 70px; */
}

.container-main-plan-red-mobile {
  margin-top: 40px;
  width: 100%;
  margin-left: 0%;
  border: 2px dashed #ffe7e7;
  padding-bottom: 30px;
  border-radius: 10px;
  background-color: #ffe7e7;
  margin-bottom: 30px;
}

.column-div {
  display: flex;
  flex-direction: column;
}
